<template>
  <div class="card-body draft-list">
      <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs && getConfigs.project_states && getConfigs.project_states.length === 0">Es wurden keine Vorlagen
        gefunden.</p>
    <Sort @sort="resortOrder($event)" />
      <div  class="draft-item" v-for="draft in getConfigs.project_states"
      :key="draft.id">
        <Headline :draft="draft"/>
      </div>
    <router-link to="/config/workflow" class="mt-5 mb-5" style="display: block;font-size: 14px;color: #6f42c1;"><i class="fa-duotone fa-chevron-left"></i> {{$t('nav.back')}}</router-link>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import  Headline from "@/routing/core/Config/Workflow/ProjectStates/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Workflow/ProjectStates/Layouts/Sort.vue";
import $ from "jquery";

export default {
  name: "List",
  computed: {
    ...mapGetters(["getEmployees",   "getConfigs", "draftActive"])
  },

  components: {
    Sort,
    Headline,
  },
  methods: {

    getProjectStates(){
      this.$store.dispatch("projectstatesFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.projectStates.ordering.value = payload.type;
        this.$store.state.filters.projectStates.direction.value = payload.direction;
      } else {
        this.$store.state.filters.projectStates.ordering.value = payload.type
      }
      this. getProjectStates();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {

  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
