<template>
  <Loading :loading="loading" v-if="loading"/>
  <div v-else>
    <div v-if="requestDetails && requestDetails.customer">
      <div class="row">
        <div class="col-4">
          <h5>Bewerbung von {{ requestDetails.customer.data.c_firstname }}
            {{ requestDetails.customer.data.c_lastname }}</h5>
          <p>{{ requestDetails.request.name }}</p>
        </div>
        <div class="col-8">
          <button class="btn btn-outline-danger btn-sm me-3 mb-1" @click="deleteRequest(requestDetails.request.id)"
                  tooltip="Bewerbung löschen"><i class="fa-duotone fa-trash"></i></button>
          <button v-if="requestDetails.request && requestDetails.request.specialOrder" :tooltip="'Ansehen'"
                  position="left" class="btn btn-outline-success btn-sm me-1"
                  @click="openSpecialFile(requestDetails.request.id)"><span
            v-if="loadingFile === requestDetails.request.id" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span><i class="fa-duotone fa-eye"></i> Sonderantrag ansehen
          </button>
          <button class="btn me-1 btn-outline-primary btn-sm mb-1" @click="exportCSV()"><i
            class="fa-duotone fa-file-export"></i> Export
          </button>
          <button class="btn me-1 btn-outline-primary btn-sm mb-1" @click="exportPDF(false)"><i
            class="fa-duotone fa-user"></i> Lebenslauf (PDF)
          </button>
          <button class="btn me-1 btn-outline-secondary btn-sm mb-1" @click="exportPDF(true)"><i
            class="fa-duotone fa-user"></i> Lebenslauf (Erweitert)
          </button>
          <button class="btn me-1 btn-outline-primary btn-sm mb-1" @click="edit = !edit" tooltip="bearbeiten"><i
            class="fa-duotone fa-edit"></i></button>
        </div>
      </div>
      <hr>
    </div>
    <div class="row" v-if="edit && requestDetails && requestDetails.request">
      <div class="col-lg-6">
        <div class="boxGrey">
          <h6>Notiz, verfassen</h6>
          <select style="width: 100%; height: 30px; margin-bottom: 24px;" v-if="sendPerMail"
                  v-model="activeDraft"
          >
            <option :value="''">- Aus Vorlage wählen -</option>
            <option :value="draft" v-for="draft in activeDrafts" :key="draft.id">{{ draft.title }}</option>
          </select>
          <template v-if="activeDraft.attachmentTitle">
            <p class="alert alert-info">{{ activeDraft.attachmentTitle }} wird als Anhang mit geschickt!</p>
          </template>
          <input class="w-100 mb-2" type="text" v-if="sendPerMail" v-model="headline">
          <editor v-model="comment"></editor>
          <input class="w-100 mb-2 mt-2" type="text" v-if="sendPerMail" placeholder="BCCs kommagetrennt" v-model="bcc">
          <input type="file" style="width: 100%; margin-top: 24px;margin-bottom: 24px;" v-if="sendPerMail" ref="file"
                 @change="readFile()">
          <button v-if="sendPerMail" style="display: none" class="btn btn-sm btn-outline-primary mb-3"
                  @click="addFileFromFilesystem = !addFileFromFilesystem"><i class="fa-duotone fa-server"></i> Datei von
            Server wählen
          </button>
          <Filesystem v-if="addFileFromFilesystem" direct-hash="545035b83190174f3dfd75d4a9d79d88" :compact-view="true"
                      :return-file="true" @returnFile="handleEmitFile"/>
          <div class="mb-4">
            <p v-if="sendPerMail">{{ attachments.length }} Anhänge!</p>
            <label for="sendPerMail"><input type="checkbox" v-model="sendPerMail" id="sendPerMail" value="1"> Per E-Mail
              an den Kunden senden</label>
          </div>
          <button class="mt-4 btn btn-outline-primary" @click="sendStateRequest()">Speichern
            <template v-if="sendPerMail"> & Senden</template>
          </button>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="boxGrey">
          <h6>Status & Notiz</h6>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'active')}"
                  @click="setStateRequest('active')">{{ $t("plugins.dfb.status.active") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'progress')}"
                  @click="setStateRequest('progress')">{{ $t("plugins.dfb.status.progress") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'checking')}"
                  @click="setStateRequest('checking')">{{ $t("plugins.dfb.status.checking") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'discus')}"
                  @click="setStateRequest('discus')">{{ $t("plugins.dfb.status.discus") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'accepted')}"
                  @click="setStateRequest('accepted')">{{ $t("plugins.dfb.status.accepted") }}
          </button>
          <template v-if="chooses && chooses.length">
            <button class="btn btn-outline-secondary me-3 w-100 smallerBtn" v-for="(choose, key) in chooses"
                    :class="{'btn-success': (requestDetails.request.state === 'prio_'+(parseInt(key)+1))}"
                    :id=" 'prio_'+(parseInt(key)+1)"
                    @click="setStateRequest('prio_'+(parseInt(key)+1));">{{ choose.title }}
            </button>
          </template>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'declined')}"
                  @click="setStateRequest('declined')">{{ $t("plugins.dfb.status.declined") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'declined_02')}"
                  @click="setStateRequest('declined_02')">{{ $t("plugins.dfb.status.declined_02") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'declined_03')}"
                  @click="setStateRequest('declined_03')">{{ $t("plugins.dfb.status.declined_03") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'declined_04')}"
                  @click="setStateRequest('declined_04')">{{ $t("plugins.dfb.status.declined_04") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'declined_05')}"
                  @click="setStateRequest('declined_05')">{{ $t("plugins.dfb.status.declined_05") }}
          </button>
          <button class="btn btn-outline-secondary me-3 w-100 smallerBtn"
                  :class="{'btn-success': (requestDetails.request.state === 'declined_user')}"
                  @click="setStateRequest('declined_user')">{{ $t("plugins.dfb.status.declined_user") }}
          </button>
        </div>
      </div>
      <!-- 7,8,15,16,23,24 -->
      <div class="col-lg-4">
        <div class="boxGrey">
          <h6>Vergangene Notizen</h6>
          <div class="notices">
            <div style="height: 100%; overflow-y: auto; overflow-x: hidden;">
              <div class="row mb-4" v-for="notice in requestDetails.comments" :key="notice.id">
                <!--{{ notice }}-->
                <div class="col-12">
                  <p class="mb-3 text-sm font-weight-bold"><img style="max-width: 40px"
                                                                :src="$filters.employeeIcon(getEmployees, notice.notice_author, 'u_id')"
                                                                class="rounded-circle img-fluid">
                    {{ $filters.formatDateTime(notice.notice_date) }} <span
                      class="badge badge-primary ms-2">{{ notice.notice_type }}</span></p>
                  <p class="mb-0 text-sm" v-html="notice.notice_message"></p>
                </div>
                <hr class="mt-4">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DFBProfile @refreshActiveRequest="getRequestDetails" :request="requestDetails.request"
                v-if="requestDetails && requestDetails.customer" :customer="requestDetails.customer.data"
                :licenses="requestDetails.customer.licenses" :attests="requestDetails.customer.attests"
                :counters="requestDetails.answers" :options="requestDetails.options"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="customerData">
          <DFBTables @refreshActiveRequest="getRequestDetails" v-if="requestDetails && requestDetails.customer"
                     :notices="requestDetails.notices" :request-id="requestDetails.request.id"
                     :active-answers="requestDetails.answers" :calc-types="calcTypes"
                     :options="requestDetails.options"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="customerData">
          <DFBTasks @refreshActiveRequest="getRequestDetails" v-if="requestDetails && requestDetails.customer"
                    :request-id="requestDetails.request.id" :tasks="requestDetails.tasks"
                    :notices="requestDetails.notices"/>
        </div>
      </div>
    </div>
    <div class="fixedButtonDFB"
         v-if="$store.getters.pluginContentDfbActiveAnwsers && $store.getters.pluginContentDfbActiveAnwsers.length">
      <button class="btn btn-danger" @click="openMultiModal = true" v-if="!openMultiModal">Mehrfachbearbeitung</button>
    </div>
    <Modal v-if="activeSpecialFile && specialFile.content">
      <div class="container">
        <div class="card" style="padding:24px; max-width: 80%">
          <div class="row">
            <div class="col-lg-4">
              <button @click="download()" class="btn btn-danger">Download</button>
            </div>
            <div class="col-lg-4">
              <button @click="rotateSinglePage()" class="btn btn-info">Drehen</button>
            </div>
            <div class="col-lg-4">
              <button @click="activeSpecialFile = null;" class="btn btn-success float-end">Vorschau schließen</button>
            </div>
          </div>
          <div class="flipableWrapper">
            <vue-pdf-embed id="flipable" annotation-layer text-layer
                           v-if="specialFile.contentType === 'application/pdf'"
                           :source="'data:'+specialFile.contentType+';base64,'+specialFile.content" type=""/>
            <embed v-else id="flipable" :src="'data:'+specialFile.contentType+';base64,'+specialFile.content">
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="openMultiModal">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h3>Mehrfachbearbeitung</h3>
          <p>Mit dieser Funktion entfernen Sie alle <strong>{{ $store.getters.pluginContentDfbActiveAnwsers.length }}
            Einträge</strong> und können dafür eine Begründung angeben:</p>
          <div class="editorDrafts ">
            <div class="row">
              <div class="col-12">
                <h4>Gründe für Änderungen</h4>
                <select @change="handleChange()" v-model="activeComment"
                        name="" id="" class="form-select mb-4">
                  <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                  <option :value="draft" v-for="draft in multiDrafts" :key="draft.id">
                    {{ draft.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="form-floating mb-3">
                  <input class="form-control" v-model="activeComment.title" type="text"/>
                  <label for="floatingInput">Titel der Vorlage</label>
                </div>
              </div>
            </div>
          </div>
          <Editor2 v-model="usedComment.comment"/>
          <div class="row">
            <div class="columns xlarge-6 large-6 medium-12 small-12">
              <button class="btn btn-warning mb-3" type="button" @click="openMultiModal = false">Abbrechen</button>
            </div>
            <div class="columns xlarge-6 large-6 medium-12 small-12">
              <button
                @click="deleteMultiValues();"
                type="button"
                class="btn btn-outline-danger float-end mb-3">Einträge löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import axios from "axios";
import DFBProfile from './Profile'
import DFBTables from './Tables'
import DFBTasks from './Tasks'
import {mapGetters} from "vuex";
import Loading from "@/components/Loading";
import Editor from "@/components/Editor2";
import Editor2 from "@/components/Editor2.vue";
import VuePdfEmbed from "vue-pdf-embed";
import Filesystem from "@/components/Filesystem.vue";

export default {
  name: "DFBActiveRequest",
  computed: {
    ...mapGetters(["getEmployees", "getApiUrl"]),
  },
  components: {
    VuePdfEmbed,
    Editor2,
    DFBTasks,
    DFBProfile, DFBTables, Editor, Loading,
    Filesystem,
  },
  props: {
    activeRequest: null,
  },
  methods: {
    download() {
      // force download pdf file
      var a = document.createElement("a"); //Create <a>
      a.href = "data:" + this.specialFile.contentType + ";base64," + this.specialFile.content; //Image Base64 Goes here
      a.download = this.specialFile.title; //File name Here
      a.click(); //Downloaded file
    },
    openSpecialFile(file) {
      this.loadingFile = file;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/specialOrder/' + file, {headers}).then((response) => {
        let shortReport = response.data.result;
        this.specialFile = shortReport.data;
        this.activeSpecialFile = file;
        this.loadingFile = null;
      }).catch((e) => {
        this.$log.debug(e);
        this.loadingFile = null;
      });
    },
    deleteMultiValues() {
      console.log(this.usedComment);
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let info = {
        toDel: this.$store.getters.pluginContentDfbActiveAnwsers,
        comment: this.usedComment.comment,
        comment_id: this.activeComment.id,
      }
      axios.post(this.$store.getters.getApiUrl + 'plugins/dfb/multiDel', info, {
        headers
      })
        .then(() => {
          this.openMultiModal = false;
          this.$store.state.pluginsContent.dfb.checkAnwers = {};
          this.$store.dispatch("toastSuccess", "Einträge wurden gelöscht!");
          this.getRequestDetails();
        })
        .catch(error => {
          this.$store.dispatch('handleApiError', error);
        })
    },
    rotateSinglePage() {
      this.degree = this.degree + 90;
      var viewerMain = document.getElementById("flipable");
      viewerMain.style.transform = "rotate(" + this.degree + "deg)";
    },
    readFile() {
      let VueInner = this;
      let base64String = null;
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        this.file = this.$refs.file.files[i];
        this.$log.debug("reading");
        this.$log.debug(this.$refs);
        this.activeFileName = this.file.name;
        if (this.file) {
          var reader = new FileReader();
          reader.onloadend = () => {
            // Use a regex to remove data url part
            base64String = reader.result
              .replace('data:', '')
              .replace(/^.+,/, '');
            VueInner.attachments.push({name: VueInner.activeFileName, data: base64String});
          };
          reader.readAsDataURL(this.file);
        }
      }
    },
    handleEmitFile(file) {
      console.log(file);
      this.addFileFromFilesystem = false;
    },
    getDrafts() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=28", {headers}).then((r) => {
        this.drafts.anschreiben = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=37", {headers}).then((r) => {
        this.drafts.a = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=38", {headers}).then((r) => {
        this.drafts.aplus = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=39", {headers}).then((r) => {
        this.drafts.b = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=40", {headers}).then((r) => {
        this.drafts.torwarta = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=41", {headers}).then((r) => {
        this.drafts.torwartb = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=42", {headers}).then((r) => {
        this.drafts.leistungskurs = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=43", {headers}).then((r) => {
        this.drafts.pro = r.data.result.data;
        this.loading = false;
      });
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=44", {headers}).then((r) => {
        this.drafts.tec = r.data.result.data;
        this.loading = false;
      });
      let mapNames = {
        "A+ Lizenz": "aplus",
        "A Lizenz": "a",
        "B+ Lizenz": "b",
        "Torwart A Lizenz": "torwarta",
        "Torwart B Lizenz": "torwartb",
        "Torwart Leistungskurs": "leistungskurs",
        "Trainer*innen-Entwickler*innen-Zertifikat": "tec",
        "Pro Lizenz": "pro",
      }
      let VueInner = this;
      setTimeout(function () {
        VueInner.activeDrafts = VueInner.drafts[mapNames[VueInner.requestDetails.request.name]];
      }, 1000);
    },
    exportCSV() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requestDetailsExport/" + this.activeRequest, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/zip;base64,' + r.data.result.data.file;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'dfb_export_gesamt_' + r.data.result.data.name + '.zip');
        window.document.body.appendChild(a);
        a.click();
      });
    },
    handleChange() {
      if (this.activeComment.id) {
        // clone this.activeComment
        let comment = Object.assign({}, this.activeComment);
        if (comment.draft) {
          this.usedComment.comment = comment.draft;
        } else {
          this.usedComment.comment = comment.title;
        }
      }
    },
    exportPDF(extended) {
      if (extended === undefined) {
        extended = 0;
      }
      if (!extended) {
        extended = 0;
      } else {
        extended = 1;
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requestPDFExport/" + this.activeRequest + "/" + extended, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/pdf;base64,' + r.data.result.data.file;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'dfb_export_' + r.data.result.data.name + '.pdf');
        window.document.body.appendChild(a);
        a.click();
      });
    },
    deleteRequest(id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let VueInner = this;
      // confirm
      this.$confirm("Diese Bewerbung sicher löschen?", "Bewerbung löschen", "question", {
        cancelButtonText: "Nein, doch nicht!",
        confirmButtonColor: "#A80000"
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + "plugins/dfb/request/" + id, {headers}).then(() => {
          this.$store.dispatch("toastSuccess", "Bewerbung wurde gelöscht!");
        });
      });
    },
    getRequestDetails(requestId, part) {
      if (part === undefined) {
        part = '';
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requestDetails/" + this.activeRequest + "?part=" + part, {headers}).then((r) => {
        if (part) {
          this.requestDetails[part] = r.data.result.data;
        } else {
          this.requestDetails = r.data.result.data;
        }
        if (this.requestDetails && this.requestDetails.tasks) {
          let a;
          for (a in this.requestDetails.tasks) {
            if (this.requestDetails.tasks[a].type === 'choose') {
              if (this.requestDetails.tasks[a].chooses) {
                this.chooses = JSON.parse(this.requestDetails.tasks[a].chooses);
              }
            }
          }
        }
        this.getDrafts();
        this.loading = false;
      });
    },
    getCalcTypes() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + 'plugins/dfb/calcTypes', {headers})
        .then(response => {
          let shortResponse = response.data.result;
          this.calcTypes = shortResponse.data;
        }).catch(error => {
        this.$log.debug(error);
      })
    },
    sendStateRequest() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "plugins/dfb/setStateRequest/", {
        requestId: this.requestDetails.request.id,
        bcc: this.bcc,
        state: this.requestDetails.request.state,
        comment: this.comment,
        sendPerMail: this.sendPerMail,
        headline: this.headline,
        attachment: this.activeFile,
        attachments: this.attachments,
        filename: this.activeFileName
      }, {headers}).then(() => {
        this.comment = '';
        this.activeFile = '';
        this.headline = '';
        this.sendPerMail = false;
        this.getRequestDetails();
      });
    },
    setStateRequest(state) {
      this.requestDetails.request.state = state;
    },
  },
  data() {
    return {
      activeSpecialFile: null,
      specialFile: null,
      chooses: [],
      openMultiModal: false,
      activeComment: {
        title: '',
        id: '',
        comment: '',
      },
      usedComment: {},
      multiDrafts: [],
      activeDrafts: [],
      drafts: {
        anschreiben: [],
        a: [],
        b: [],
        aplus: [],
        torwarta: [],
        torwartb: [],
        pro: [],
        tec: [],
        leistungskurs: [],
      },
      edit: false,
      activeFile: null,
      activeFileName: null,
      loading: true,
      requestDetails: null,
      options: null,
      calcTypes: null,
      comment: '',
      sendPerMail: false,
      activeDraft: '',
      headline: "Es gibt Neuigkeiten zu Ihrer Bewerbung.",
      bcc: null,
      attachments: [],
      degree: 0,
      addFileFromFilesystem: false,
    };
  },
  created() {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    axios.get(this.$store.state.apiUrl + "draft/search?group_id=35", {headers}).then((r) => {
      this.multiDrafts = r.data.result.data;
    });
    this.$store.state.pluginsContent.dfb.checkAnwers = {};
    this.getRequestDetails();
    this.getCalcTypes();
    window.addEventListener("refreshTasksDFB", this.getDrafts);
  },
  watch: {
    activeRequest() {
      this.getRequestDetails();
    },
    activeFile() {
      this.$log.debug("actie File");
      this.$log.debug(this.activeFile);
    },
    activeDraft() {
      this.attachments = [];
      if (this.activeDraft) {
        this.comment = this.activeDraft.draft;
        this.headline = this.activeDraft.title;
        if (this.activeDraft.attachment_id) {
          this.attachments.push(this.activeDraft.attachment_id);
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("refreshTasksDFB", this.getRequestDetails);
  }
};
</script>
<style lang="scss">
.ck-content {
  min-height: 200px;
}

.product-item {
  cursor: pointer;
}

.boxGrey {
  padding: 24px;
  background-color: #eee;
  min-height: 500px;
}

.fixedButtonDFB .btn {
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 24px 48px;
  /* background: red; */
  /* color: #fff; */
}

.flipableWrapper {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
</style>
