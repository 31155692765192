<template>
  <div
    class="h-100 show"
    id="sidenav-collapse-main"
  >

    <div class="navScroller">
    <ul class="navbar-nav">

      <li class="nav-item" v-if="(getUser && getMenu && getMenu.dashboard && (!getUser.acl.menu || getUser.acl.menu.dashboard === 'yes'))">
        <sidenav-item
            url="/dashboard"
            :class="getRoute() === 'dashboard' ? 'active' : ''"
            :navText="$t('nav.dashboard')"
            :bicon="'fa-table-columns'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="(getUser && getMenu && getMenu.customers && (! getUser.acl ||  getUser.acl.customers && getUser.acl.customers.read && (!getUser.acl || getUser.acl.customers.read === 'all' || getUser.acl.customer.read === 'own')) && (!getUser.acl.menu || getUser.acl.menu.customers === 'yes'))">
      <sidenav-item
          url="/customers"
          :class="getRoute() === 'customers' ? 'active' : ''"
          :navText="$t('nav.customers')"
          :bicon="'fa-users'"
      >
      </sidenav-item>
      </li>

      <li class="nav-item" v-if="(getUser && getMenu && getMenu.documents && (!getUser.acl.menu || getUser.acl.menu.documents === 'yes'))">
        <sidenav-item
            url="/documents"
            :class="getRoute() === 'documents' ? 'active' : ''"
            :navText="$t('nav.documents')"
            :bicon="'fa-file-contract'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item" v-if="(getUser && getMenu && getMenu.products && (!getUser.acl.menu || getUser.acl.menu.products === 'yes'))">
        <sidenav-item
            url="/products"
            :class="getRoute() === 'products' ? 'active' : ''"
            :navText="$t('nav.products')"
            :bicon="'fa-tags'"
        >
        </sidenav-item>
      </li>

<!--      <li class="nav-item" v-if="(getMenu && getMenu.abonnements)">-->
<!--        <sidenav-item-->
<!--            url="/abonnements"-->
<!--            :class="getRoute() === 'abonnements' ? 'active' : ''"-->
<!--            :navText="'Abonnements'"-->
<!--            :bicon="'fa-rectangle-history-circle-plus'"-->
<!--        >-->
<!--        </sidenav-item>-->
<!--      </li>-->

      <li class="nav-item" v-if="(getUser && getMenu && getMenu.activities && (!getUser.acl.menu || getUser.acl.menu.activities === 'yes'))">
        <sidenav-item
            url="/activities"
            :class="getRoute() === 'activities' ? 'active' : ''"
            :navText="$t('nav.activity')"
            :bicon="'fa-chart-network'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item" v-if="(getUser && getMenu && getMenu.queue && (!getUser.acl.menu || getUser.acl.menu.queue === 'yes'))">
        <sidenav-item
            url="/queue"
            :class="getRoute() === 'queue' ? 'active' : ''"
            :navText="$t('nav.queue')"
            :bicon="'fa-snake'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item" v-if="(getUser && getMenu && getMenu.stats  && (!getUser.acl.menu || getUser.acl.menu.reports === 'yes'))">
        <sidenav-item
          url="/stats/reports"
          :class="getRoute() === 'stats' ? 'active' : ''"
          :navText="$t('nav.reports')"
          :bicon="'fa-chart-column'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="(getMenu && getMenu.drafts && getUser && (!getUser.acl || getUser.acl.documents.umsatzs === 'yes')  && (!getUser.acl.menu || getUser.acl.menu.drafts === 'yes'))">
        <sidenav-item
          url="/drafts"
          :class="getRoute() === 'drafts' ? 'active' : ''"
          :navText="$t('nav.drafts')"
          :bicon="'fa-layer-group'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="((getUser && getUser.acl ) && ( !getUser.acl.menu ||  getUser.acl.menu && getUser.acl.menu.config_basic && getUser.acl.menu.config_basic === 'yes'))">
        <sidenav-item
          url="/config/basic"
          :class="$route.path === '/config/basic' ? 'active' : ''"
          :navText="$t('nav.config')"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>
      <li class="nav-category"  v-if="(getMenu && getMenu.dfb)">

        <div class="category-link category-link-trainer fw-bold">{{ $t('nav.trainer') }}</div>

      </li>
      <li class="nav-item orange" v-if="(getMenu && getMenu.dfb)">

        <sidenav-item
          url="/dfb"

          :navText="$t('nav.applications')"
          :bicon="'fa-file-alt'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item orange" v-if="(getMenu && getMenu.dfb)">

        <sidenav-item
          url="/dfb/phases"
          :class="$route.path === '/dfb/phases' ? 'active' : ''"
          :navText="$t('nav.applicationsPhases')"
          :bicon="'fa-diagram-project'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item orange" v-if="(getMenu && getMenu.dfb)">

        <sidenav-item
          url="/dfb/institutions"
          :class="$route.path === '/dfb/institutions' ? 'active' : ''"
          :navText="$t('nav.institutions')"
          :bicon="'fa-building'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item orange" v-if="(getMenu && getMenu.dfb)">
        <sidenav-item
          url="/dfb/approvals"
          :class="$route.path === '/dfb/approvals' ? 'active' : ''"
          :navText="$t('nav.approvals')"
          :bicon="'fa-hand-holding-box'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item orange"  v-if="dev && (getMenu && getMenu.dfb || getPlugins && getPlugins.extension && getPlugins.extension.bdfl )">

        <sidenav-item
          url="/licenseRenewal"
          :class="getRoute() === 'licenseRenewal' ? 'active' : ''"
          :navText="$t('nav.licenseRenewal')"
          :bicon="'fa-id-card'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item orange" v-if="dev && getMenu && getMenu.dfb">

      </li>
      <template v-if="getMenu && getUser && getUser.acl && (getMenu.employees || getMenu.times)  && (!getUser.acl.menu || getUser && getUser.acl && getUser.acl.menu.times === 'yes')">
      <li class="nav-category" >

        <div class="category-link category-link-employees fw-bold">Personal</div>

      </li>
      <li class="nav-item green" v-if="(getMenu && getMenu.employees) && ( getUser && getUser.acl && getUser.acl.employees && getUser.acl.employees.read === 'all' ||  getUser && getUser.acl && getUser.acl.employees && getUser.acl.employees.read === 'own' ) && (!getUser.acl.menu || getUser.acl.menu.employees === 'yes') ">
        <sidenav-item
            url="/employees"
            :class="getRoute() === 'employees' ? 'active' : ''"
            :navText="$t('nav.employees')"
            :bicon="'fa-id-card-clip'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item green" v-if="(getMenu && getMenu.times  && (!getUser.acl.menu || getUser && getUser.acl.menu.times === 'yes'))">
        <sidenav-item
            url="/times"
            :class="getRoute() === 'times' ? 'active' : ''"
            :navText="$t('nav.time')"
            :bicon="'fa-calendar-clock'"
        >
        </sidenav-item>
      </li>
<!--v-if="getMenu && getMenu.legal"-->
      <li class="nav-item green" v-if="getMenu && (getMenu.employees || getMenu.times)  && (!getUser.acl.menu || getUser && getUser.acl.menu.config_employees === 'yes') " >
        <sidenav-item
          url="/config/employee"
          :class="$route.path === '/config/Employee' ? 'active' : ''"
          :navText="$t('nav.config')"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>
      </template>
      <li class="nav-category" v-if="getMenu && (getMenu.tasks || getMenu.events || getMenu.newsletter)">

        <div class="category-link category-link-workflows fw-bold">Workflow</div>

      </li>
      <!-- TODO-ralf ACL getMenu.newsletter -->
      <li class="nav-item pink" v-if="(getUser && getMenu && getMenu.newsletter)">
        <sidenav-item
          url="/newsletters"
          :class="getRoute() === 'newsletter' ? 'active' : ''"
          :navText="$t('nav.newsletter')"
          :bicon="'fa-envelopes-bulk'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item pink" v-if="(getMenu && getMenu.tasks && getUser  && (!getUser.acl || ( getUser && getUser.acl && getUser.acl.issues && getUser.acl.issues === 'yes'))  && (getUser && getUser.acl && !getUser.acl.menu || getUser.acl.menu.issues === 'yes')) ">
        <sidenav-item
            url="/tasks"
            :class="getRoute() === 'tasks' ? 'active' : ''"
            :navText="$t('nav.tasks')"
            :bicon="'fa-clipboard-list-check'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item pink" v-if="(getUser && getMenu && getMenu.projects  && (!getUser.acl.menu || getUser.acl.menu.projects === 'yes'))">
        <sidenav-item
          url="/projects"
          :class="getRoute() === 'projects' ? 'active' : ''"
          :navText="$t('nav.projects')"
          :bicon="'fa-diagram-project'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item pink" v-if="(getMenu && getMenu.events && getUser  && (getUser && getUser.acl && !getUser.acl.menu || getUser && getUser.acl && getUser.acl.menu.events === 'yes'))" >
        <sidenav-item
            url="/events"
            :class="getRoute() === 'events' ? 'active' : ''"
            :navText="$t('nav.events')"
            :bicon="'fa-calendar-star'"
        >
        </sidenav-item>

      </li>


      <li class="nav-item pink" v-if="((getMenu && getMenu.tasks && getUser && getUser.acl &&  getUser.acl.issues && getUser.acl.issues === 'yes') || (getMenu && getMenu.events)  && (getUser && getUser.acl && !getUser.acl.menu || getUser && getUser.acl && getUser.acl.menu && getUser.acl.menu.events === 'yes'))">
        <sidenav-item
          url="/config/workflow"
          :class="getRoute() === '/config/workflow' ? 'active' : ''"
          :navText="$t('nav.config')"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>
<template v-if="(!getUser || !getUser.acl || (getUser && getUser.acl && getUser.acl.documents &&  getUser.acl.documents.umsatzs === 'yes'))">


      <li class="nav-category" v-if="getMenu && (getMenu.files || getMenu.sepa || getMenu.accounting ||  getMenu.legal) ">

        <div class="category-link category-link-accounting fw-bold">Buchhaltung</div>

      </li>
      <li class="nav-item red" v-if="(getMenu && getMenu.files  && getUser && (!getUser.acl.menu || getUser.acl.menu.files === 'yes'))">
        <sidenav-item
            url="/files"
            :class="getRoute() === 'files' ? 'active' : ''"
            :navText="$t('nav.files')"
            :bicon="'fa-file-arrow-up'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item red" v-if="(getMenu && getMenu.sepa  && getUser && (!getUser.acl.menu || getUser.acl.menu.sepa === 'yes'))">
        <sidenav-item
            url="/sepa"
            :class="getRoute() === 'sepa' ? 'active' : ''"
            :navText="$t('nav.sepa')"
            :bicon="'fa-wallet'"
        >
        </sidenav-item>
      </li>
      <li class="nav-item red" v-if="(getMenu && getMenu.accounting  && getUser && (!getUser.acl.menu || getUser.acl.menu.accounting === 'yes'))">
        <sidenav-item
            url="/accounting"
            :class="getRoute() === 'accounting' ? 'active' : ''"
            :navText="$t('nav.accounting')"
            :bicon="'fa-receipt'"
        >
        </sidenav-item>
      </li>



      <!--      <li class="nav-item red" v-if="(getMenu && getMenu.bankaccountmovements)">-->
<!--        <sidenav-item-->
<!--            url="/bankaccountmovements"-->
<!--            :class="getRoute() === 'bankaccountmovements' ? 'active' : ''"-->
<!--            :navText="'Kontobewegungen'"-->
<!--            :bicon="'fa-credit-card'"-->
<!--        >-->
<!--        </sidenav-item>-->
<!--      </li>-->



      <li class="nav-item red" v-if="(getMenu && getMenu.legal   && getUser && (!getUser.acl.menu || getUser.acl.menu.legal === 'yes'))">
        <sidenav-item
            url="/legal"
            :class="getRoute() === 'legal' ? 'active' : ''"
            :navText="$t('nav.legal')"
            :bicon="'fa-gavel'"
        >
        </sidenav-item>
      </li>

      <li class="nav-item red" v-if="(getMenu && getMenu.sepa  && getUser && (!getUser.acl.menu || getUser.acl.menu.config_accounting === 'yes'))">
        <sidenav-item
          url="/config/accounting"
          :class="$route.path === '/config/accounting' ? 'active' : ''"
          :navText="$t('nav.config')"
          :bicon="'fa-cog'"
        >
        </sidenav-item>
      </li>





</template>

      <li class="nav-item orange" v-if="!$store.state.loggedInState">
        <sidenav-item
          url="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="$t('nav.login')"
          :bicon="'fa-sign-in-alt'"
        >
          <template v-slot:icon>
            <i class="fa-duotone fa-right-to-bracket"></i>
          </template>
        </sidenav-item>
      </li>
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/signup"-->
<!--          :class="getRoute() === 'signup' ? 'active' : ''"-->
<!--          :navText="this.$store.state.isRTL ? 'اشتراك' : 'Sign Up'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-collection text-info text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->

    </ul></div>
  </div>
  <div class="pt-1 pb-1 sidenav-footer bottom-0">
    <sidenav-card
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";
import {mapGetters} from 'vuex';
export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      dev: false,
      title: "sketch.connect",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  computed: {
    ...mapGetters(["getUser", "getMenu", "getPlugins"])
  },
  components: {
    SidenavItem,
    SidenavCard
  },
  created() {

  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>

<style lang="scss">

$orange:  #fd7e14 !default;
.nav-category {

  width: 100%;

  .category-link {

    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    margin-top: 24px;
    padding-left: 0;
    padding-right: 1rem;
    color: #344767;
    font-size: 0.8rem;

    &.category-link-employees {

      border-bottom: .09rem solid #42a62b;

    }

    &.category-link-workflows {

      border-bottom: .09rem solid #7b1991;

    }

    &.category-link-accounting {

      border-bottom: .09rem solid #ff4e3d;

    }

    &.category-link-configuration {

      border-bottom: .09rem solid $orange;

    }

  }

}

</style>
