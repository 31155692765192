// drafts.js
import axios from "axios";

export const state = {
  lists: {
    drafts: {
      data: [],
      offset: 0,
      complete:0,
      limit: 20,
      selected: {},
      loading: false,
    },
    events: {
      data: [],
      offset: 0,
      complete:0,
      limit: 20,
      selected: {},
      loading: false,
    },
    newsletters: {
      data: [],
      offset: 0,
      complete:0,
      limit: 20,
      selected: {},
      loading: false,
    },
    customers: {
      data: [],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false
    },
    legals: {
      data: [],
      offset: 0,
      limit: 20,
      loading: false,
    },
    products: {
      data: [],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false,
    },
    productsAll: {
      data: [],
      offset: 0,
      limit: 0,
      selected: {}
    },
    categorys:{
      data: [],
      offset: 0,
      limit: 0,
      selected: {},
      loading: false,
    },
    tasks:{
      data: [],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false,
    },
    sepas:{
      data: [],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false,
    },
    addresses: {
      data: [],
      offset: 0,
      limit: 0,
      customer_id: false,
      loading: false,
    },
    documents: {
      data: [],
      offset: 0,
      limit: 20,
      document_id: false,
      selected: {},
      loading: false,
    },
    queues:{
      data: [],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false,
    },
    employees:{
      data: [],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false,
    },
    projects:{
      data:[],
      offset: 0,
      limit: 20,
      selected:{},
      loading: false,
    },
    languageOverrides: {
      data: [],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false,
    },
    attests:{
      data:[],
      offset: 0,
      limit: 20,
      selected: {},
      loading: false
    }
  },
}

export const mutations = {
  toggleAll(state, listName) {
    this.commit('initializeSelectedState', listName);

    const list = state.lists[listName];
    list.data.forEach(item => {
      if (!list.selected[item.id]) {
        list.selected[item.id] = true;
      } else {
        list.selected[item.id] = false;
      }
    });
  },
  initializeSelectedState(state, listName) {
    const list = state.lists[listName];
    if (!list.selected) {
      list.selected = {};
    }

    if (list && list.data && Array.isArray(list.data)) {
      list.data.forEach(item => {
        if (!list.selected[item.id]) {
          list.selected[item.id] = false;
        }
      });
    }
  },
  save2copy(state, listName) {
    // post item and unset id first
    const list = state.lists[listName];
    const selected = list.selected;
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    axios.post(state.apiUrl + "batch/"+listName+"/copy", selected, {headers}).then((e) => {

    }).catch((e) => {
      this.$store.dispatch("handleApiResponse", e.response);
    })
  },
  deleteSelected(state, listName) {
    // get selected items
    const list = state.lists[listName];
    const selected = list.selected;
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    //are you sure?

    axios.post(state.apiUrl + "batch/"+listName+"/delete", selected, {headers}).then((e) => {
      // reload list
       this.dispatch(listName+"Filter");

    })
  },
  archiveSelected(state, listName) {
    // get selected items
    const list = state.lists[listName];
    const selected = list.selected;
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    //are you sure?

    axios.post(state.apiUrl + "batch/"+listName+"/archive", selected, {headers}).then((e) => {
      // reload list
       this.dispatch(listName+"Filter");

    })
  },
  toggleSelected(state, payload) {
    // Add id to selected list, remove it already exists
    if (!state.lists[payload.type].selected[payload.id]) {
      state.lists[payload.type].selected[payload.id] = payload.id;
    } else {
      delete state.lists[payload.type].selected[payload.id];
    }
  },
  initializeSelected(state, payload) {

    if (!state.lists[payload.listName]) {
      state.lists[payload.listName] = { data: [], selected: {} };
    }

    state.lists[payload.listName].selected = payload.selected;
  },
  setAddresses(state, payload) {
    state.lists.addresses = payload;
  },
  setProducts(state, payload) {
    state.lists.products.data = payload;
  },
  setAllProducts(state, payload) {
    state.lists.productsAll = payload;
  },
  setLanguageOverrides(state, overrides) {
    state.languageOverrides = overrides;
  },
  setDrafts(state, payload) {
    state.lists.drafts = payload;
  },
  setQueues(state, payload) {
    state.lists.queues = payload;
  },
  setEvents(state, payload) {
    state.lists.events = payload;
  },
  setActiveItem(state, payload) {
    state.activeItems[payload.type] = payload.data;
  },
  customerSet(state, payload) {
    state.lists.customers = payload;
  },
  eventSet(state, payload) {
    state.lists.events = payload;
  },
  newsletterSet(state, payload) {
    state.lists.newsletters = payload;
  },
  queueSet(state, payload) {
    state.lists.queues = payload;
  },
  taskSet(state, payload) {
    state.lists.tasks = payload;
  },
  productSet(state, payload) {
    state.lists.products = payload;
  },

  legalSet(state, payload) {
    state.lists.legals = payload;
  },
  sepaSet(state, payload) {
    state.lists.sepas = payload;
  },
  documentSet(state, payload) {
    state.lists.documents = payload;
  },
  projectSet(state, payload) {
    state.lists.projects = payload;
  },
  attestSet(state, payload) {
    state.lists.attests = payload;
  }
}

export const actions = {
  // Aktionen für drafts
  async exportSelected({ commit, state }, { listName, exportFields }) {
    const list = state.lists[listName];
    const selected = list.selected;
    const filters = state.filters[listName];
    const fields = exportFields;
    const headers = { "crmAuth": localStorage.getItem("sketchManagerToken") };

    await axios.post(state.apiUrl + "batch/" + listName + "/exportCSV", { selected, filters, fields }, { headers })
      .then((response) => {
        let file = response.data.result.data;
        const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
        var a = document.createElement('a');
        a.href = linkSource;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Error during export:", error);
        // Handle error appropriately
      });
  },
  async reportSelected({ commit, state, dispatch }, { listName, exportFields, title, subtype }) {
    const list = state.lists[listName];
    const selected = list.selected;
    const filters = state.filters[listName];
    const fields = exportFields;
    const headers = { "crmAuth": localStorage.getItem("sketchManagerToken") };

    await axios.post(state.apiUrl + "batch/" + listName + "/report", { selected, filters, fields, info:{title:title, subType:subtype} }, { headers })
      .then((response) => {
        if(response.data.result.success === true){
          dispatch('toastSuccess', 'Bericht erfolgreich erstellt.');
        } else{
          dispatch('toastError', 'Fehler beim erstellen des Berichts.');
        }
        commit('closeModal', 'report');
      })
      .catch((error) => {
        console.error("Error during export:", error);
        // Handle error appropriately
      });
  }
}

export const getters = {
  taskList: state => {
    return state.lists.tasks;
  },
  eventList: state => {
    return state.lists.events;
  },
  newsletterList: state => {
    return state.lists.newsletters;
  },
  queueList: state => {
    return state.lists.queues;
  },
  taskActive: state => {
    return state.activeItems.task;
  },
  productList: state => {
    return state.lists.products;
  },
  productAllList: state => {
    return state.lists.productsAll;
  },
  legalList: state => {
    return state.lists.legals;
  },
  sepaList: state => {
    return state.lists.sepas;
  },
  sepaSelected: state => {
    return state.lists.sepas.selected;
  },
  draftList: state => {
    return state.lists.drafts;
  },
  categoryList: state => {
    return state.lists.categories;
  },
  customerList: state => {

    return state.lists.customers;
  },
  projectList: state => {
    return state.lists.projects;
  },
  languageOverridesList: state => {
    return state.lists.languageOverrides;
  },
  attestList: state => {
    return state.lists.attests;
  },
  customerSelected: state => {

    let selected = [];
    for (let key in state.lists.customers.selected) {
      if (state.lists.customers.selected[key]) {
      selected.push(key);
      }
    }
    return selected;
  },
  employeeSelected: state => {

      let selected = [];
      for (let key in state.lists.employees.selected) {
        if (state.lists.employees.selected[key]) {
        selected.push(key);
        }
      }
      return selected;
  },
  documentList: state => {
    return state.lists.documents;
  },
  addressList: state => {
    return state.lists.addresses;
  },
  employeeList: state => {
    return state.lists.employees;
  },
}
