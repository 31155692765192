<template>
  <div class="draftLineWrap projectLine"
       :class="(project.prepared && project.prepared.typeString) ? project.prepared.typeString : ''">
    <div class="lineitem first l-checkbox">
      <div class="checkbox">
        <input type="checkbox"
               :id="'id_'+project.id"
               :value="project.id"
               v-if="projectList && projectList.selected"
               v-model="projectList.selected[project.id]"/>
        <label :for="'id_'+project.id"></label>
      </div>
    </div>
    <div class="lineitem l-title" @click="setDraft(project)">
      {{ project.title }}
    </div>
    <div class="lineitem l-state">
      <span class="badge badge-xs" v-if="project.state" :class="project.state">{{ $t('project.state.' + project.state) }}</span>
      <span v-else>-</span>
    </div>
    <div class="lineitem l-date">
      <small>{{ $filters.formatDate(project.crm_created_date) }}</small>
    </div>

    <Holzkraft :project="project" mode="listHeadline"
               v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft"/>
    <div class="lineitem l-avatar ml-auto">
      <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, project.employee_id, 'id')"
           class="sketchAvatar" v-if="project.employee_id">
    </div>
    <div class="lineitem last ms-2">
      <template v-if="projectActive && projectActive.id === project.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeProject');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up" @click="setDraft(project)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import {useToast} from "vue-toastification";
import Editor2 from "@/components/Editor2.vue";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";

export default {
  name: "Headline",
  data() {
    return {
      modalContent: null,
      sendContent: false,
      sendContentTitle: '',
      sendContentText: '',
      orderConfirmationText: '',
      toast: useToast(),
      address: {},
      notice: {
        document_id: null,
        notice_message: ''
      },
      storno: {
        title: '',
        message: '',
        reason: ''
      }
    }
  },
  props: {
    project: Object,
  },
  components: {
    Holzkraft,
    Editor2
  },
  computed: {
    ...mapGetters(["projectActive", "getEmployees", "getPlugins", "getConfigs", "projectList"])
  },
  methods: {
    setDraft(project) {
      this.$store.dispatch("setActiveProject", project);
      this.$router.push({name: 'ProjectDetails', params: {id: project.id}})
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  }
}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
