<template>

  <div class="productItem activeProduct">

    <div class="card">
      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> Aktivität bearbeiten</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeModal', 'ConfigWorkflowActivities');$store.commit('closeConfigWorkflowActivities');">
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
          class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
        </button>
        <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
          class="fa-duotone fa-trash"></i> {{ $t("globals.delete") }}
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Name</label>
              <input class="form-control" type="text" v-model="d.title">
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Zustand</label>

              <select  class="form-select" v-model="d.state">
                <option :value="opt.value" v-for="opt in stateOptions">{{opt.label}}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Sortierung</label>
              <input class="form-control" type="number" v-model="d.ordering" step="1">
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <label>{{$t("globals.color")}}</label>
              <input class="form-control" type="color" v-model="d.color">
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <label> {{$t("globals.iconList")}}</label>
              <br>
              <a href="https://fontawesome.com/search?o=r&s=duotone" target="_blank">
                Font Awesome Duotone</a>
            </div>
          </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="form-group">
                <label>{{$t("globals.sign")}}</label>
                <input class="form-control" type="text" v-model="d.icon"  >
              </div>
            </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <label>Zugewiesenes Produkt</label>
              {{productAllList}}
              <Multiselect :options="productAllList"
                           v-model="d.crm_product_id" :multiple="false"
                           valueProp="id"
                           :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                           :isOpen="true" openDirection="top" track-by="p_title" :searchable="true"
                           placeholder="Produkt wählen" :allow-empty="true" label="p_title"
                           :select-label="'Produkt wählen'"></Multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Workflow/IssueActivities/Layouts/Headline.vue";
import Multiselect from '@vueform/multiselect'
import $ from "jquery";


export default {
  name: "ActivitiesDetails",
  components: {
    Headline,
    Editor2,
    Multiselect
  },
  computed: {
    activity() {
      return activity
    },
    ...mapGetters(["getEmployees", "getConfigs", "getConfigWorkflowActivities", "productList"]),
  },
  data() {
    return {
      d: {
        title: "",
        state: "calc",
        ordering: 0,
        crm_product_id: null,
      },
      isNew: true,
      stateOptions: [
        {value: "calc", label: this.$t("states.issueTracker.calc")},
        {value: "order", label: this.$t("states.issueTracker.order")},
        {value: "bug", label: this.$t("states.issueTracker.bug")},
      ],
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "configs/issueActivities/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess

          // Load Products
          this.$store.dispatch("loadConfig", {type: "issue", subType: 'activities', percent: 98, message: 'fast fertig... noch 2%!'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Aktivität wurde erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigWorkflowActivities');
          });

        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "configs/issueActivities/", VueInner.d, {headers}).then(() => {


          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "issue", subType: 'activities', percent: 98, message: 'fast fertig... noch 2%!'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigWorkflowActivities');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Aktivität wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigWorkflowActivities');
          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll die Aktivität wirklich gelöscht werden?', 'Aktivität löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "configs/issueActivities/" + VueInner.d.id, {headers}).then(() => {
          // close

          // dispatch toastSuccess
          this.$store.dispatch("loadConfig",{type:"issue", subType: 'activities', percent: 98, message: 'fast fertig... noch 2%!'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Aktivität wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigWorkflowActivities');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
         this.d = this.getConfigWorkflowActivities

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>

