<template>
  <div class="accountData ps-1 pe-1" :class="position">
    <div v-if="accountData && accountData.sorted">
      <div class="row">
        <div class="col-4">
               <span @click="contractToInvoice(accountData.sorted.key)" class="iconAction me-2 btn btn-sm btn-danger"
                     tooltip="Nächsten Zeitraum verlängern">
      <i :class="'fas fa-rotate-right fa-duotone'"></i>
    </span>
          <span @click="downloadPDF(accountData.sorted.key)" class="iconAction btn btn-sm btn-danger"
                tooltip="Konto drucken">
      <i :class="'fas fa-print fa-duotone'"></i>
    </span>
        </div>
        <div class="col-8">
          <p class="hint pe-3" style="text-align: right" v-if="accountData && accountData.sorted">
            <span class="infoDataSplitted">
              <span>Konto: {{ accountData.sorted.account.title }} <a :href="'/#/config/accounting/keys/'+accountData.sorted.account.id" target="_blank"><i class="fa-duotone fa-pencil"></i></a></span>
              <template v-if="accountData.sorted.account._connectedCustomers && accountData.sorted.account._connectedCustomers.length > 0">
                <br>
                Kunde:
              <span v-for="cus in accountData.sorted.account._connectedCustomers">{{cus.c_customer_id}} <template v-if="cus.c_company">{{cus.c_company}}</template><template v-else>{{cus.c_lastname}} {{cus.c_firstname}}</template> <a :href="'/#/customers/'+cus.id" target="_blank"><i class="fa-duotone fa-pencil"></i></a><br></span>
              </template>

            </span>
            <template v-if="accountData.sorted.account.startSum"> (Startbetrag:
              <template v-if="accountData.sorted.account.type === 'bank' && accountData.sorted.account.startSum >= 0">
                +
              </template>
              <template v-if="accountData.sorted.account.type === 'bank' && accountData.sorted.account.startSum < 0">-
              </template>
              <template v-if="accountData.sorted.account.type !== 'bank' && accountData.sorted.account.startSum < 0">+
              </template>
              <template v-if="accountData.sorted.account.type !== 'bank' && accountData.sorted.account.startSum >= 0">
                -
              </template>
              {{ $filters.priceEuro(accountData.sorted.account.startSum) }})
            </template>
          </p>
        </div>
      </div>
      <div class="calcs row p-1 pt-0" style="max-width: 100%">
        <div class="calc col-lg-3 p-0">
          <p style="text-align: right; font-size: 14px;"><span
            style="font-size:10px">Konto</span><br>{{ accountData.sorted.key }} </p>
        </div>
        <div class="calc col-lg-3  p-0">
          <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Soll</span>
            <br>{{ $filters.priceEuro(accountData.sorted.soll.sum) }}</p>
        </div>
        <div class="calc col-lg-3  p-0">
          <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Haben</span>
            <br>{{ $filters.priceEuro(accountData.sorted.haben.sum) }}</p>
        </div>
        <div class="calc col-lg-3  p-0">
          <p style="text-align: right; font-size: 14px;"><span style="font-size:10px">Saldo</span><br>
            {{ $filters.priceEuro(accountData.sorted.diff.sum) }} {{ accountData.sorted.diff.type }}</p>
        </div>
        <hr>
      </div>
      <div style="min-height: 50vh;height: 450px; overflow-y: scroll; overflow-x: hidden;">
        <div class="calcs row p-2 pt-0" style="max-width: 100%">
          <div class="calc  p-0">
            <table class="accountingDataTable table align-items-center p-2 mb-0"
                   v-if="accountData.data && accountData.data.length > 0">
              <thead>
              <tr>
                <th>Aktionen</th>
                <th>Belegdat.</th>
                <th>Belegfelder</th>
                <th class="align-right">Soll</th>
                <th class="align-right">Haben</th>
                <th class="align-right">Gegenkto</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="acc in paginatedData" :key="acc.id">
                <tr  v-if="acc.visible === 1 || acc.visible === '1'">
                  <td>
                    <div class="btn-group">
                      <button @click="copyBookingEmit(acc)"
                              style="margin-bottom: 0; vertical-align: middle; color: #fff;"
                              title="Eintrag kopieren" class="btn btn-secondary btn-xs   btn-outline-success  "><i
                        class="fa-duotone fa-clipboard"></i></button>
                      <template v-if="!acc.exported">
                        <button @click="editBookingEmit(acc)" style="margin-bottom: 0; vertical-align: middle"
                                title="Editieren" class="btn  btn-xs  btn-success"><i
                          class="fa-duotone fa-pencil"></i></button>
                        <button @click="removeBookingEmit(acc.id)" style="margin-bottom: 0; vertical-align: middle"
                                title="Löschen" class="btn btn-xs   btn-primary"><i class="fa-duotone fa-close"></i>
                        </button>
                      </template>
                    </div>
                  </td>
                  <td>{{ $filters.formatDate(acc.datum) }}</td>
                  <td>
                    {{ acc.beleg1 }}
                    <template v-if="acc.beleg2"><br><span class="hint">{{ acc.beleg2 }}</span></template>
                  </td>
                  <td class="align-right">
                    <template v-if="acc.sh === 'S'">{{ $filters.priceEuro(acc.betrag) }}</template>
                  </td>
                  <td class="align-right">
                    <template v-if="acc.sh === 'H'">{{ $filters.priceEuro(acc.betrag) }}</template>
                  </td>
                  <td class="align-right">{{ acc.gegenkonto }}</td>

                </tr>
              </template>
              </tbody>
            </table>

          </div>

        </div>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button type="button" class="page-link" @click="changePage(currentPage - 1)">
              <i class="fa-duotone fa-arrow-left"></i>
            </button>
          </li>

          <li class="page-item" v-for="n in visiblePages" :key="n" :class="{ active: n === currentPage, disabled: n === '...'}">
            <button type="button" class="page-link" @click="n !== '...' && changePage(n)">
              {{ n }}
            </button>
          </li>

          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button type="button" class="page-link" @click="changePage(currentPage + 1)">
              <i class="fa-duotone fa-arrow-right"></i>
            </button>
          </li>
        </ul>
      </nav>

    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: 'AccountData',
  data() {
    return {
      currentPage: 1,
      pageSize: 500,
    }
  },
  props: {
    position: {
      type: String,
      required: true
    },
    accountData: {
      type: Object,
      required: true
    },
    isKonto: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    visiblePages() {
      const range = [];
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const padding = 2; // how many pages to show around the current page

      if (totalPages <= 9) {
        // If the total number of pages is 9 or less, display all pages
        for (let i = 1; i <= totalPages; i++) {
          range.push(i);
        }
      } else {
        // Always include the first page
        range.push(1);

        // Define range boundaries
        let start = Math.max(2, currentPage - padding);
        let end = Math.min(totalPages - 1, currentPage + padding);

        // If current page is close to the beginning, adjust the range end
        if (currentPage <= 4) {
          end = 5;
        }

        // If current page is close to the end, adjust the range start
        if (currentPage >= totalPages - 3) {
          start = totalPages - 4;
        }

        // Add ellipsis to indicate a gap to the left
        if (start > 2) {
          range.push('...');
        }

        // Generate the page numbers in the range
        for (let i = start; i <= end; i++) {
          range.push(i);
        }

        // Add ellipsis to indicate a gap to the right
        if (end < totalPages - 1) {
          range.push('...');
        }

        // Always include the last page
        range.push(totalPages);
      }

      return range;
    },
    totalPages() {
      return this.accountData && this.accountData.data
        ? Math.ceil(this.accountData.data.length / this.pageSize)
        : 0;
    },
    paginatedData() {
      if (!this.accountData || !this.accountData.data) {
        return [];
      }
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.accountData.data.slice(start, end);
    }
  },
  created() {

  },
  methods: {
    downloadPDF(key) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "accounting/pdf/account?accountingKey=" + key, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/pdf;base64,' + r.data.result.data;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'export_account_' + key + '.pdf');
        window.document.body.appendChild(a);
        a.click();
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    contractToInvoice(key) {
      let payload = {accountingKey: key};
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.getters.getApiUrl + "accounting/contractToInvoice", payload, {headers}).then((r) => {
        this.$emit("updateAccountData", {position: this.position, accountingKey: key});
        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    copyBookingEmit(booking) {
      this.$emit("copyBooking", booking);
    },
    editBookingEmit(booking) {
      this.$emit("editBooking", booking);
    },
    removeBookingEmit(id) {
      this.$emit("removeBooking", id);
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }
      this.currentPage = page;
    }
  }
}
</script>

