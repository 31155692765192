<template>
  <div class=" fade show " tabindex="-1" :class="(modal) ? 'modal' : ''"
        style="display: block;">
    <div :class="(modal) ? 'modal-dialog modal-lg': ''">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <template v-if="projectItem.id">
              Projekt bearbeiten
            </template>
            <template v-else>
              Neues Projekt
            </template>
          </h5>
          <div class="btn-group btn-sm ml-auto">
            <template v-if="projectItem.id">
              <button type="button" class="btn btn-outline-primary btn-sm" @click="updateProject"><i
                class="fa-duotone fa-floppy-disk"></i> Speichern
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn  btn-outline-primary btn-sm " @click="postProject"><i
                class="fa-duotone fa-floppy-disk"></i> Speichern
              </button>
            </template>
            <button type="button" class="btn btn-outline-secondary btn-sm" v-if="modal"
                    @click="$store.commit('closeModal', 'project');"><i class="fa-duotone fa-xmark-circle"></i>
              Abbrechen
            </button>
          </div>
          <button type="button" class="btn-close" @click="$store.commit('closeModal', 'project');"
                  v-if="modal"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="projectTitle">Projektname</label>
            <input type="text" class="form-control" id="projectTitle" v-model="projectItem.title">
          </div>
          <div class="row">
            <div class="col-lg-6 col-12"><div class="form-group">
              <label>Projektstatus</label>
              <select class="form-select"   v-model="projectItem.state_id">
                <option v-for="info in getConfigs.project_states" :value="info.id">{{info.title}}

                </option>
              </select>
            </div></div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
              <label>Projektleiter</label>
              <select class="form-select" name="" id="" v-model="projectItem.employee_id">
                <option value="">Bitte wählen</option>
                <option v-for="info in getEmployees" :value="info.id">{{
                    info.e_name
                  }}
                </option>
              </select>
            </div>

            </div>
          </div>

          <div class="form-group"  v-if="!projectItem.id">
            <label>Kunde wählen</label>


            <CustomerSearch  :onlyid="true" v-model="projectItem.crm_customer_id" />
          </div>

          <Holzkraft :project="projectItem" v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft && projectItem && projectItem.id"
                     mode="enter"/>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label for="projectTitle">{{$t('project.start')}}</label>
                <VueDatePicker v-model="projectItem.start" model-type="yyyy-MM-dd"
                               format="dd.MM.yyyy" placeholder="Projektstart" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true">
                </VueDatePicker>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>{{$t('project.end')}}</label>
                <VueDatePicker v-model="projectItem.end" model-type="yyyy-MM-dd"
                               format="dd.MM.yyyy" placeholder="Projektende" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true">
                </VueDatePicker>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="projectDesc">Beschreibung</label>
            <Editor2 v-model="projectItem.description"></Editor2>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label for="projectDesc">External Ressource</label>
                <input type="text" class="form-control" v-model="projectItem.external_ressource"
                       placeholder="URL"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <label>Tags</label>
                <multiselect v-model="projectItem.tags" :id="'tags'"
                             :valueProp="'id'"
                             :options="getConfigs.project_tags"
                             :multiple="true"
                             :label="'name'"
                             :closeOnSelect="false"
                             :allow-empty="true"
                             :create-option="true"
                             :clearOnSelect="false"
                             :searchable="true"
                             :open-direction="'top'"
                             :taggable="true"
                             @tag="addTag"
                             :placeholder="$t('globals.chooseTags')"
                             :tag-placeholder="$t('globals.insertNewTag')"
                             track-by="name"
                             mode="tags"
                ></multiselect>
              </div>
            </div>
          </div>

          <template v-if="projectItem && projectItem.id">
          <!--add Checkbox group for dev and live -->
          <div class="plugins" v-if="getPlugins.infos && getPlugins.infos.accessData">
            <div class="inlinePasswordEdit">
              <template v-if="showPassEdit === false">
                <button class="btn btn-outline-primary" @click="showPassEdit = true;"><i
                  class="fa-duotone fa-plus-circle"></i> eingetragene Passwörter
                </button>
              </template>
              <template v-else>
                <button class="btn btn-outline-primary" @click="showPassEdit = false;"><i
                  class="fa-duotone fa-close"></i>
                  Schließen
                </button>
              </template>
            </div>
            <CustomerPasswords v-if="showPassEdit === true && environment" @refresh-passwords="getAccessData" :hash="projectItem._customerHash" />
            <div class="form-group">
              <label for="environment">Environment:</label>
              <ul class="tabNav subtabs">
                <li v-for="en in environment" :key="en" class="firstToUpper"
                    @click="setSubtab(en)"
                    :class="(activeSubtab === en) ? 'active' : ''"> {{ en }}
                </li>
              </ul>
            </div>
            <div class="repeatForm" v-for="env in environment">
              <template v-if="activeSubtab === env && projectItem && projectItem.plugins && projectItem.plugins.accessData">
                <div class="card">
                  <h6 class="card-header">{{ env }}</h6>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label>URL</label>
                          <input type="text" class="form-control" v-model="projectItem.plugins.accessData[env].url"
                                 placeholder="URL"/>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>FTP-Server</label>
                          <select name="" id="" v-model="projectItem.plugins.accessData[env].ftp" class="form-select">
                            <option value="">- FTP-Server wählen -</option>
                            <template  v-for="info in accessData" v-if="accessData.length">


                            <option :value="info.id" v-if="info.type ==='ftp'">{{
                                info.a_title
                              }}  <template v-if="info.a_title !== info.url">{{ info.url }}</template>
                            </option>
                            </template>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label>Webseite</label>
                          <select name="" id="" v-model="projectItem.plugins.accessData[env].backend" class="form-select">
                            <option value="">- Websitezugang wählen -</option>
                            <template  v-for="info in accessData" v-if="accessData.length">


                              <option :value="info.id" v-if="info.type ==='backend'">{{
                                  info.a_title
                                }}  <template v-if="info.a_title !== info.url">{{ info.url }}</template>
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>.htaccess</label>
                          <select name="" id="" v-model="projectItem.plugins.accessData[env].htaccess" class="form-select">
                            <option value="">- Zugang wählen -</option>
                            <template  v-for="info in accessData" v-if="accessData.length">


                              <option :value="info.id" v-if="info.type ==='htaccess'">{{
                                  info.a_title
                                }}  <template v-if="info.a_title !== info.url">{{ info.url }}</template>
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <label>Provider Typ</label>
                          <select v-model="projectItem.plugins.accessData[env].providerType" class="form-select">
                            <option value="">- Typ wählen -</option>
                            <option v-for="pro in providers" :value="pro">{{ pro }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label>Provider-Zugang</label>
                          <select name="" id="" v-model="projectItem.plugins.accessData[env].provider" class="form-select">
                            <option value="">- Provider wählen -</option>
                            <template  v-for="info in accessData" v-if="accessData.length">


                              <option :value="info.id" v-if="info.type ==='provider'">{{
                                  info.a_title
                                }}  <template v-if="info.a_title !== info.url">{{ info.url }}</template>
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label>PHP</label>
                          <select v-model="projectItem.plugins.accessData[env].php" class="form-select">
                            <option value="">- PHP Version wählen -</option>
                            <option v-for="phpVersion in phpversions" :value="phpVersion.value">{{
                                phpVersion.label
                              }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label>URLs Git</label>
                          <textarea class="form-control" v-model="projectItem.plugins.accessData[env].gits"></textarea>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>URLs Cron</label>
                          <textarea class="form-control" v-model="projectItem.plugins.accessData[env].crons"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label>Software</label>
                          <select v-model="projectItem.plugins.accessData[env].software" class="form-select">
                            <option value="">- Software wählen -</option>
                            <option v-for="software in softwares" :value="software.label">{{ software.label }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Wartung</label>
                          <select v-model="projectItem.plugins.accessData[env].wartung" class="form-select">
                            <option value="">- Wartung wählen -</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="projectDesc">Beschreibung</label>
                          <Editor2 v-model="projectItem.plugins.accessData[env].description"></Editor2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div></template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomerPasswords from "@/routing/core/Customers/Passwords.vue";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";
import CustomerSearch from "@/filter/components/customersearch.vue";
import {mapGetters} from "vuex";
import customersearch from "@/filter/components/customersearch.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import multiselect from "@vueform/multiselect";

export default {
  name: "Edit",
  emits: ['refresh-project-list'],
  components: {VueDatePicker, customersearch, Editor2, CustomerPasswords, Holzkraft, CustomerSearch, multiselect},
  data() {
    return {
      projects: [],
      projectItem: {
        id: '',
        title: '',
        description: '',
        state_id: '',
        employee_id: '',
        external_ressource: '',
        plugins: {
          accessData: {}}
      },
      newTask: false,
      newProject: false,
      showPassEdit: false,
      activeSubtab: 'live',
      accessData: [],
      today: new Date().toISOString().slice(0, 10),
      environment: ['live', 'dev', 'api', 'admin', 'other'],
      states: ['prepare', 'offer', 'order', 'progress', 'finished', 'deleted'],
      providers: ['hosting-intern','hosting-extern', 'df-intern', 'df-extern', 'strato', 'mittwald', 'hetzner', 'allinkl', '1und1/ionos','alfahosting','host-europe', 'andere'],
      phpversions: [
        {label: '8.4', value: '8.40'},
        {label: '8.3', value: '8.30'},
        {label: '8.2', value: '8.20'},
        {label: '8.1', value: '8.10'},
        {label: '8.0', value: '8.00'},
        {label: '7.4', value: '7.40'},
        {label: '7.3', value: '7.30'},
        {label: '7.2', value: '7.20'},
        {label: '7.1', value: '7.10'},
        {label: '7.0', value: '7.00'},
        {label: '5.6', value: '5.60'},
      ],
      softwares: [
        {label: "Joomla5"},{label: 'Joomla4'}, {label: "VueJS"}, {label: "Wordpress"}, {label: "Joomla3"}
      ]
    }
  },
  mounted() {
    this.getAccessData();
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "getPlugins", "getConfigs"])
  },
  props: {
    newProjectItem: {},
    key: false,
    modal: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    postProject() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        hash: this.customerActive.hash,
        data: this.projectItem
      }
      axios.post(this.$store.state.apiUrl + "project", postContent, {headers}).then((r) => {
        this.$emit("refresh-project-list");
        this.$store.commit('closeModal', 'project');
        this.$store.dispatch("toastSuccess", "Eintrag erfolgreich gespeichert.");
      }).catch((e) => {
        this.$store.commit("handleApiResponse", e.response);
      });
    },
    getAccessData() {
      let customer_id;
      if(this.newProjectItem && this.newProjectItem.crm_customer_id){
        customer_id = this.newProjectItem.crm_customer_id;
      } else {
        if(this.customerActive && this.customerActive.id) {
          customer_id = this.customerActive.id;
        } else {
          return;
        }
      }
      if (customer_id === undefined) {
        return;
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customer_id + "/accessData", {headers}).then((r) => {
        this.accessData = r.data.result.data;
      });
    },
    updateProject() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let hash;
      if(this.newProjectItem && this.newProjectItem.crm_customer_id){
        hash = this.newProjectItem._customerHash;
      } else {
        hash = this.customerActive.hash;
      }
      let postContent = {
        hash: hash,
        data: this.projectItem
      }
      axios.put(this.$store.state.apiUrl + "project/" + this.projectItem.id, postContent, {headers}).then((r) => {
        this.$store.commit('closeModal', 'project');
        this.$emit("refresh-project-list");
        this.$store.dispatch("toastSuccess", "Eintrag erfolgreich gespeichert.");
      });
    },
    setSubtab(subtab) {
      this.activeSubtab = subtab;

      this.activeSubtab = subtab;

      if (this.$route.name.includes('Customers')) {
        // Handle subtab in Customers view
        let customerActiveId = this.newProjectItem?.crm_customer_id || this.customerActive.id;
        this.$router.push({
          name: 'CustomersTabsSubtabs',
          params: { id: customerActiveId, tab: this.$route.params.tab, subtab: subtab }
        });
      } else if (this.$route.name.includes('Projects')) {
        // Handle only tab in Projects view
        let projectActiveId = this.newProjectItem?.crm_project_id || this.projectActive.id;
        this.$router.push({
          name: 'ProjectTabs',
          params: { id: projectActiveId, tab: subtab }
        });
      }
    },
    runAccessData() {
      this.projectItem = this.newProjectItem;
      if (this.projectItem) {
        let a;
        if (this.getPlugins && this.getPlugins.infos && this.getPlugins.infos.accessData && ( !this.projectItem.plugins || !this.projectItem.plugins.accessData)) {
          this.projectItem.plugins = {
            accessData: {},
          }
        }

        for (a in this.environment) {
          if(this.projectItem && this.projectItem.plugins && this.projectItem.plugins.accessData){
          if (  this.projectItem.plugins.accessData[this.environment[a]] === undefined || this.projectItem.plugins.accessData[this.environment[a]] === null) {
            this.projectItem.plugins.accessData[this.environment[a]] = {
              id: '',
              project_id: '',
              provider: '',
              url: '',
              providerType: '',
              ftp: '',
              php: '',
              gits: '',
              crons: '',
              software: '',
              wartung: '',
              description: '',
              backend: '',
              htaccess: ''
            }
          }}
        }

      }
    },
    addTag(newTag) {
      // random id
      this.projectItem.tags.push({name: newTag, code: Math.random().toString(36).substr(2, 9)})
    },
  },

  created() {
  this.runAccessData();

  },
  watch: {
    newProjectItem: {
      handler(newVal) {
        this.projectItem = {...newVal}; // Update projectItem when newProjectItem changes
      },
      immediate: true,
      deep: true
    }
  }

}
</script>
