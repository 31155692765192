<template>
  <template v-if="getPlugins && (filter.plugin === undefined || (getPlugins.infos && getPlugins.infos[filter.plugin]))">
  <input type="text" class="form-control small" v-model="$store.state.filters[type][filter.name].value"
         @keyup.enter="$store.dispatch(type+'Filter');" :placeholder="getTranslation(filter.name)"/>
  </template>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  name: "textinput",
  data() {
    return {
      customers: "",
      options: [],

    };
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs", "getPlugins"]),
  },
  methods: {
    getTranslation(filterKey) {
      // Check for an override first
      if (this.filter.title) {
        return this.filter.title;
      } else {


        // Fallback to the default translation
        return this.$t('filters.' + filterKey);
      }
    }
  },
  created() {
    this.options = this.getConfigs[this.filter.source];
  }
}
</script>

