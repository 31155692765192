<template>
  <div class="appView plugins dfb plugin-list">

      <div class="row mb-3">
        <div class="col-12">
          <ul class="tabNav">
            <li  @click="navigateToTab('requests')" :class="(activeView === 'requests') ? 'active' : '' ">
             <i class="fa-duotone fa-file-alt"></i> Bewerbungen
            </li>
            <li  @click="navigateToTab('requestTypes')" :class="(activeView === 'requestTypes') ? 'active' : '' ">
              <i class="fa-duotone fa-diagram-project"></i> Bewerbungsphasen
            </li>
            <li @click="navigateToTab('institutions')" :class="(activeView === 'institutions') ? 'active' : '' ">
              <i class="fa-duotone fa-building"></i> Institutionen
            </li>
            <li @click="navigateToTab('freigaben')" :class="(activeView === 'freigaben') ? 'active': ''"><i class="fa-duotone fa-hand-holding-box"></i> Freigaben</li>
          </ul>
        </div>
      </div>
    <component :is="activeView"/>

  </div>
</template>
<script>
import CalcTypes from "@/routing/plugins/dfb/subs/CalcTypes";
import Requests from "@/routing/plugins/dfb/subs/Requests";
import RequestTypes from "@/routing/plugins/dfb/subs/RequestTypes";
import Questions from "@/routing/plugins/dfb/subs/Questions";
import Institutions from "@/routing/plugins/dfb/subs/Institutions.vue";
import Freigaben from "@/routing/plugins/dfb/subs/Freigaben.vue";
export default {
  name: "DFBDashboard",
  components: {
    CalcTypes, Requests, RequestTypes, Questions, Institutions, Freigaben
  },
  methods: {
    setProduct(product) {
      this.activeProduct = product;
    },
    setActiveViewFromRoute() {
      const routeMap = {
        "/dfb": "requests", // Note the leading slash
        "/dfb/phases": "requestTypes",
        "/dfb/institutions": "institutions",
        "/dfb/approvals": "freigaben"
      };

      this.activeView = routeMap[this.$route.path] || "requests"; // Default to "requests"

    },
    navigateToTab(view) {
      const pathMap = {
        requests: "/dfb",
        requestTypes: "/dfb/phases",
        institutions: "/dfb/institutions",
        freigaben: "/dfb/approvals",
      };
      const targetPath = pathMap[view];
      console.log("NAVIGATING TO: ", targetPath);
      this.$router.push(targetPath);
    },
  },
  data() {
    return {
      activeView: 'requests',
    };
  },
  created() {
    this.setActiveViewFromRoute();
  },
  watch: {
    $route() {
      this.setActiveViewFromRoute();
    },
  },

};
</script>
<style lang="scss">
</style>
