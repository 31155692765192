<template>
  <div class="plugin-item headline">
<div class="pluginLineWrap dfbApprovals">
      <div class="lineitem l-date">
        <span @click="emitSort('valid_from')" :class="(sortDirections.valid_from) ? 'active' : ''">Gültig</span>
        <i v-if="sortDirections.valid_from === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.valid_from === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-date">
      <span @click="emitSort('crm_created_date')" :class="(sortDirections.crm_created_date) ? 'active' : ''">Erstellt</span>
      <i v-if="sortDirections.crm_created_date === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.crm_created_date === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem  l-progress l-state">
      <span @click="emitSort('progress')"  :class="(sortDirections.progress) ? 'active' : ''">Prozess</span>
      <i v-if="sortDirections.progress === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.progress === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
      <div class="lineitem   l-state">
        <span @click="emitSort('state')"  :class="(sortDirections.state) ? 'active' : ''">Status</span>
        <i v-if="sortDirections.state === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.state === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
    <div class="lineitem l-customerinfo">
      <span @click="emitSort('title')" :class="(sortDirections.title) ? 'active' : ''"  >Kunde</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-info">
      <span @click="emitSort('info')" :class="(sortDirections.info) ? 'active' : ''"  >Info</span>
      <i v-if="sortDirections.info === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.info === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
      <div class="lineitem l-reviewer" >
        <span @click="emitSort('reviewer')"  :class="(sortDirections.reviewer) ? 'active' : ''">überprüft von</span>
        <i v-if="sortDirections.reviewer === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.reviewer === 'desc'" class="fa-duotone fa-arrow-down"></i>

      </div>
      <div class="lineitem l-verified">
        <span @click="emitSort('verified')" :class="(sortDirections.verified) ? 'active' : ''" > </span>
        <i v-if="sortDirections.verified === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="sortDirections.verified === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>




      <div class="lineitem l-actions ml-auto">

      </div>


      </div></div>

</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        valid_from: '',
        crm_created_date: '',

      }

    }
  },
  props: {
    document: Object,
  },
  computed: {
    ...mapGetters([ "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
