<template>
  <div class="holzkraftWrap"
       v-if="project && project.plugins && project.plugins.holzkraft && project.plugins.holzkraft[0] && (mode === 'enter' || mode ==='show') ">
    <template v-if="mode === 'enter'">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Projektumsatz</label>
            <input type="number" class="form-control" v-model="project.plugins.holzkraft[0].projektGesamtkosten"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label>kWel-Gesamt</label>
            <input type="number" class="form-control" v-model="project.plugins.holzkraft[0].kwel"/>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label>Anlage</label>
            <Multiselect :options="anlagen"
                         :multiple="true"
                         :searchable="false"
                         :close-on-select="true"
                         :show-labels="false"
                         :canClear="true"
                         :canDeselect="true"
                         valueProp="value"
                         class="mb-3"
                         :label="'name'"
                         locale="de"
                         :placeholder="'Anlage wählen'"
                         :showNoOptions="false"
                         v-model="project.plugins.holzkraft[0].anzahlAnlagen"
                         openDirection="bottom"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label>Angabe in %</label>
            <input type="number" class="form-control" v-model="project.plugins.holzkraft[0].abschlusswahrscheinlichkeit"
                   min="0" max="100"/>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label class="me-3">Gebrauchtanlage?</label>
            <div class="btn-group btn-group-yesno" role="group">
              <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_yes" value="1"
                     v-model="project.plugins.holzkraft[0].gebrauchtanlage">
              <label class="btn btn-outline-primary" for="gebrauchtanlage_yes">Ja</label>
              <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_no" value="0"
                     v-model="project.plugins.holzkraft[0].gebrauchtanlage">
              <label class="btn btn-outline-danger" for="gebrauchtanlage_no">Nein</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12"><p><strong>Lieferanschrift</strong></p></div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Land</label>
            <input type="text" class="form-control" v-model="project.plugins.holzkraft[0].lieferanschrift_land"/>
          </div>
        </div>
      </div>
    </template>
    <template v-if="mode === 'show'">
      <div class="row mt-3">
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>Projektumsatz:&nbsp;</label>
            <strong>
              {{ project.plugins.holzkraft[0].projektGesamtkosten }}</strong> €
          </div>
        </div>
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>kWel-Gesamt:&nbsp;</label><strong>{{ project.plugins.holzkraft[0].kwel }}</strong>
          </div>
        </div>
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>Anlage:&nbsp;</label><strong>
            {{ project.plugins.holzkraft[0].anzahlAnlagen }}</strong>
          </div>
        </div>
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>Angabe in %:&nbsp;</label><strong>
            {{ project.plugins.holzkraft[0].abschlusswahrscheinlichkeit }}%</strong>
          </div>
        </div>
        <div class="col-lg  col-md-6 col-12">
          <div class="form-group">
            <label class="me-3">Gebrauchtanlage?</label>
            <template v-if="project.plugins.holzkraft[0].gebrauchtanlage === 1"><span tooltip="Ja"><i
              class="fa-duotone fa-circle-check green"></i></span></template>
            <template v-else>
              <i class="fa-duotone fa-circle-xmark red"></i>
            </template>
          </div>
        </div>
      </div>
      <!-- expand if to all values with or-->
      <div class="form-group" v-if="project.plugins.holzkraft[0].lieferanschrift_land">
        <label class="color">Lieferaddresse</label>
        <small>
          {{ project.plugins.holzkraft[0].lieferanschrift_land }}
        </small>
      </div>
    </template>
  </div>
  <template v-if="mode === 'listSort'">
    <div class="lineitem fromPlugin l-anlagen"><span :class="(sortDirections.anzahlAnlagen) ? 'active' : ''"
                                                     @click="emitSort('anzahlAnlagen')">
      <i v-if="sortDirections.anzahlAnlagen === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.anzahlAnlagen === 'desc'" class="fa-duotone fa-arrow-down"></i>
      Anlage(n)</span>
    </div>
    <div class="lineitem fromPlugin l-kwel"><span @click="emitSort('kwel')"
                                                  :class="(sortDirections.kwel) ? 'active' : ''">
      <i v-if="sortDirections.kwel === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.kwel === 'desc'" class="fa-duotone fa-arrow-down"></i>
      kw</span></div>
    <div class="lineitem fromPlugin l-ppkw"><span @click="emitSort('preisProKw')"
                                                  :class="(sortDirections.preisProKw) ? 'active' : ''">
      <i v-if="sortDirections.preisProKw === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.preisProKw === 'desc'" class="fa-duotone fa-arrow-down"></i>
      € / kw</span></div>
    <div class="lineitem fromPlugin l-pgesamt"><span @click="emitSort('projektGesamtkosten')"
                                                     :class="(sortDirections.projektGesamtkosten) ? 'active' : ''">
      <i v-if="sortDirections.projektGesamtkosten === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.projektGesamtkosten === 'desc'" class="fa-duotone fa-arrow-down"></i>
      Umsatz</span></div>
  </template>
  <template v-if="mode === 'listHeadline'">
    <template v-if="project && project.plugins && project.plugins.holzkraft[0]">
      <div class="lineitem l-anlagen fromPlugin" style="text-align: center;">
        <small>{{ project.plugins.holzkraft[0].anzahlAnlagen }}</small>
      </div>
      <div class="lineitem l-kwel fromPlugin">{{ project.plugins.holzkraft[0].kwel }}</div>
      <div class="lineitem l-ppkw fromPlugin">{{ project.plugins.holzkraft[0].preisProKw }}</div>
      <div class="lineitem l-pgesamt fromPlugin">{{ project.plugins.holzkraft[0].projektGesamtkosten }}</div>
    </template>
  </template>
  <template
    v-if="mode === 'editCustomer2auskommentiert' && $store && $store.state && $store.state.activeItems && $store.state.activeItems.customer && $store.state.activeItems.customer.projects && $store.state.activeItems.customer.projects[0] && $store.state.activeItems.customer.projects[0].plugins && $store.state.activeItems.customer.projects[0].plugins.holzkraft && $store.state.activeItems.customer.projects[0].plugins.holzkraft[0]">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="projectTitle">Projektname</label>
          <input type="text" class="form-control" id="projectTitle"
                 v-model="$store.state.activeItems.customer.projects[0].title">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group" v-if="getConfigs">
          <label>Projektstatus</label>
          <select style="width: 100%;" name="" id="" v-model="$store.state.activeItems.customer.projects[0].state_id">
            <option v-for="info in getConfigs.project_states" :value="info.id">{{ info.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="projectTitle">Geplante erste Anzahlung</label>
          <VueDatePicker v-model="$store.state.activeItems.customer.projects[0].start" model-type="yyyy-MM-dd"
                         format="dd.MM.yyyy" placeholder="Projektstart" :auto-apply="true" :close-on-auto-apply="true"
                         locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Letzte Auslieferung</label>
          <VueDatePicker v-model="$store.state.activeItems.customer.projects[0].end" model-type="yyyy-MM-dd"
                         format="dd.MM.yyyy" placeholder="Projektende" :auto-apply="true" :close-on-auto-apply="true"
                         locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Projektumsatz</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].projektGesamtkosten"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>kWel-Gesamt</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].kwel"/>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>Anlagentyp</label>
          <Multiselect :options="anlagen"
                       :multiple="true"
                       :searchable="false"
                       :close-on-select="true"
                       :show-labels="false"
                       :canClear="true"
                       :canDeselect="true"
                       valueProp="value"
                       class="mb-3"
                       :label="'name'"
                       locale="de"
                       :placeholder="'Anlage wählen'"
                       :showNoOptions="false"
                       v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].anzahlAnlagen"
                       openDirection="bottom"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>Preis / kW</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].preisProKw"/>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>Abschlusswahrscheinlichkeit in %</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].abschlusswahrscheinlichkeit"
                 min="0" max="100"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label class="me-3">Gebrauchtanlage?</label>
          <div class="btn-group btn-group-yesno" role="group">
            <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_yes" value="1"
                   v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].gebrauchtanlage">
            <label class="btn btn-outline-primary" for="gebrauchtanlage_yes">Ja</label>
            <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_no" value="0"
                   v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].gebrauchtanlage">
            <label class="btn btn-outline-danger" for="gebrauchtanlage_no">Nein</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
      </div>
    </div>
    <div class="row">
      <div class="col-12"><p><strong>Lieferanschrift</strong></p></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label>Land</label>
          <input type="text" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].lieferanschrift_land"/>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import Multiselect from "@vueform/multiselect";
import vuex from "vuex";
import axios from "axios";

export default {
  name: "HolzkraftProjects",
  emits: ['sort'],
  components: {
    VueDatePicker, Multiselect
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }
      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }
      // Emit the clicked type and direction
      this.$emit('sort', {type: clickedType, direction: this.sortDirections[clickedType]});
    },

  },
  data() {
    return {
      states: ['prepare', 'offer', 'order', 'progress', 'finished', 'deleted'],
      anlagen: [
        {"name": "HKA 35", "value": "HKA 35"},
        {"name": "HKA 50", "value": "HKA 50"},
        {"name": "HKA 70", "value": "HKA 70"},
        {"name": "HKA 70 Prozess", "value": "HKA 70 Prozess"},
        {"name": "HKA 300", "value": "HKA 300"},
        {"name": "HKA 700", "value": "HKA 700"},
        {"name": "HV 70", "value": "HV 70"},
        {"name": "HV 70 Direktgas", "value": "HV 70 Direktgas"},
        {"name": "Trockne", "value": "Trockne"}
      ]
    };
  },
  props: {
    sortDirections: {
      type: Object,
      required: false
    },
    project: {
      type: Object,
      required: false
    },
    mode: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(["customerActive", "getPlugins", "getConfigs"])
  },

};
</script>
