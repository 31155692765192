<template>
  <!-- check if filter.source is array -->
  <template v-if="getPlugins && (filter.plugin === undefined || (getPlugins.infos && getPlugins.infos[filter.plugin]))">


  <template v-if="Array.isArray(filter.source)">
    <Multiselect class="small"
                 v-if="filter.source"
                 :id="filter.name+filter.source"
                 :options="filter.source"
                 :multiple="false"
                 :label="filter.labelType"
                 :valueProp="'id'"
                 v-model="$store.state.filters[type][filter.name].value"
                 @update:model-value="refreshList()"
                 :placeholder="getTranslation(filter.name)"
                 :searchable="true"
                 :closeOnSelect="true"
                 :noOptionsText="$t('filters.noOptionsText')"
    >

    </Multiselect>
  </template>
  <template v-else>
    <template v-if="filter.source === 'countries'">

      <Multiselect class="small" v-if="filter.source !=='tbd' && options"
                   :id="filter.name+filter.source"
                   :options="getSelectedCountries"
                   :multiple="false"
                   :label="filter.labelType"
                   :internal-search="true"
                   :close-on-select="true"
                   :clean-clear="true"
                   :clear-on-search="true"
                   :show-labels="false"
                   v-model="$store.state.filters[type][filter.name].value"
                   @update:model-value="refreshList()"
                   :placeholder="getTranslation(filter.name)"
                   :valueProp="'id'"
                   :searchable="true"
                   :closeOnSelect="true"
                   :noOptionsText="$t('filters.noOptionsText')"
      >

      </Multiselect>
    </template>
    <template v-else>
      <Multiselect class="small" v-if="filter.source !=='tbd' && options"
                   :id="filter.name+filter.source"
                   :options="options"
                   :multiple="false"
                   :label="filter.labelType"
                   :internal-search="true"
                   :close-on-select="true"
                   :clean-clear="true"
                   :clear-on-search="true"
                   :show-labels="false"
                   v-model="$store.state.filters[type][filter.name].value"
                   @update:model-value="refreshList()"
                   :placeholder="getTranslation(filter.name)"
                   :valueProp="'id'"
                   :searchable="true"
                   :closeOnSelect="true"
                   :noOptionsText="$t('filters.noOptionsText')"
      >

      </Multiselect>
    </template>
  </template></template>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import {mapGetters} from 'vuex';
export default {
  name: "list",
  data() {
    return {
      customers: "",
      options: [],
      overrides: { },
    };
  },
  components: {
    Multiselect
  },
  methods: {
    refreshList() {
      this.$store.state.lists[this.type].offset = 0;
      this.$store.dispatch(this.type + 'Filter');
    },
    clearSelection() {
      this.$store.state.filters[this.type][this.filter.name].value = null;

      this.refreshList();
    },
    getTranslation(filterKey) {
      if(this.filter.title){
        return this.filter.title;
      } else {
      const overrideKey = this.overrides[filterKey];
      if (overrideKey) {
        return overrideKey;
      }
      // Fallback to the default translation
      return this.$t('filters.' + filterKey);}
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs", "getSelectedCountries", "getPlugins"]),
  },
  created() {
    if (Array.isArray(this.filter.source)) {
      this.options = this.filter.source;
    } else {
      this.options = this.getConfigs[this.filter.source];
    }
    if(this.getConfigs && this.getConfigs.params_values){
      this.overrides.structure1 = this.getConfigs.params_values.title_structure1;
      this.overrides.structure2 = this.getConfigs.params_values.title_structure2;
      this.overrides.structure3 = this.getConfigs.params_values.title_structure3;
      this.overrides.structure4 = this.getConfigs.params_values.title_structure4;
    }
  },
}
</script>

<script setup>
</script>
